<template>
<CModalExtended
  :title="$t('label.nueva')+ ' '+$t('label.stowageMovementTransaction') "
  color="dark"
  size="lg"
  :close-on-backdrop="false"
  :show.sync="modalActive"
>
  <form @submit.stop.prevent="submit" class="company-form">
    <CRow class="justify-content-center">
      <CCol sm="12">
          <CCard class="card-simple">
            <CCardBody>
              <CRow>
                <CCol sm="12"> 
                  <CInput
                    v-model.trim="$v.transaccion.TransacStowageNameEs.$model"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-6'}"
                    :invalid-feedback="errorMessage($v.transaccion.TransacStowageNameEs)"
                    :is-valid="hasError($v.transaccion.TransacStowageNameEs)"
                    :label="$t('label.name')+' '+('(ES)')"
                    addLabelClasses="required text-right"
                    :placeholder="$t('label.transactionName')+' '+('(ES)')"
                    maxlength="100"
                  >
                  </CInput>
                </CCol>
                <CCol sm="12"> 
                  <CInput
                    v-model.trim="$v.transaccion.TransacStowageName.$model"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-6'}"
                    :invalid-feedback="errorMessage($v.transaccion.TransacStowageName)"
                    :is-valid="hasError($v.transaccion.TransacStowageName)"
                    :label="$t('label.name')+' '+('(EN)')"
                    addLabelClasses="required text-right"
                    :placeholder="$t('label.transactionName')+' '+('(EN)')"
                    maxlength="100"
                  >
                  </CInput>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
      </CCol>
      <CCol sm="12">
        <dataTableExtended
     class="align-center-row-datatable"
          size="sm"
          :fields="fields"
          :items="movimientosOptions"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :loading="isLoading"
          id="tableTrans"
           
        >
          <template #loading>
            <loading/>
          </template>
          <template #checked="{ item }">
            <td class="center-cell">
              <CInputCheckbox
                custom
                class="checkbook-filter"
                :checked="item.initialActive"
                @update:checked="handleMovimiento($event, item)"
              />
            </td>
          </template>
        </dataTableExtended>
        <p v-if="!selectedMov" class="mt-2 text-danger">
          * {{$t('label.must')+$t('label.toSelect')+$t('label.atLeast')}} 1 {{$t('label.movementType')}}
        </p>
      </CCol>
    </CRow>       
  </form>
  <template #footer><!-- :disabled="isSubmitValid"-->
    <CButton
      color="add"
      class="d-flex align-items-center"
      :disabled="isSubmit"
      @click.stop="submit"
    >
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>

    <!--
      <div v-if="!isSubmit">
        <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
      </div>
      <div v-if="isSubmit">
        <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ml-1">{{$t('button.accept')}}</span>
      </div>-->
    </CButton>
    <CButton
      color="wipe"
      :disabled="isSubmit"
      class="d-flex align-items-center"
      @click.stop="toggle(false)"
    >
      <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
    </CButton>
  </template>
</CModalExtended>
</template>

<script>
import TransacFormValidations from '@/_validations/estiba/TransacFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import { TransaccionMixin } from '@/_mixins/estiba';
import GeneralMixin from '@/_mixins/general';
import ModalMixin from '@/_mixins/modal';

function fields(){
  return  [
    { 
      key: 'Nro', 
      label: '#',
      filter: false,
      _style: 'width:5%; text-align:center;'
    },
    { key: 'MotionName', label:this.$t('label.movement'), _classes:"text-left"},
    { 
      key: 'checked', 
      label: this.$t('agridTable.applyFilter'), 
      _style: 'width:1%', 
      sorter: false, 
      filter: false,
      _classes:"text-center"
    }
  ];
}
//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    isLoading: false,
    transaccion: {
      MovStowageId: '',
      TransacStowageNameEs: '',
      TransacStowageName: ''
    },
    movimientos: [],
    selectedMov: null
  }
}

//Methods
function submit() {
  try {
    this.isSubmit = true;
    this.$v.transaccion.$touch();
      if (this.$v.transaccion.$error) {
        this.isSubmit = false;
        throw this.$t('label.errorsPleaseCheck');
      }

    let TransacStowageJson = {
      MovStowageId: this.transaccion.MovStowageId,
      TransacStowageNameEs: this.transaccion.TransacStowageNameEs,
      TransacStowageName: this.transaccion.TransacStowageName
    };

    this.$http.post("TransacStowage-insert", TransacStowageJson, { root: 'TransacStowageJson' })
    .then(response => {
      if (response && response.status === 200) {
        const messageSuccess = response.data.data[0].Response;
        this.resetForm();
        this.notifySuccess({text: messageSuccess})
        this.modalActive = false
        this.$emit('submited');
        this.isSubmit=false;
      }
    }).catch(err => {
      this.notifyError({text: err});
    }).then(() => {
      this.isSubmit = false;
    });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function toggle(newVal) {
  if(newVal)
    this.getMovimientos();
  else
    this.resetForm();

  this.modalActive = newVal;
}
function resetForm() {
  this.transaccion.MovStowageId = '';
  this.transaccion.TransacStowageNameEs = '';
  this.transaccion.TransacStowageName = '';
  this.movimientos = [];
  this.selectedMov = null;
  this.$v.$reset();
}
function getMovimientos() {
  this.isLoading = true;

  this.$http.get("MovStowage-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.movimientos = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}

function handleMovimiento(event, item) {
  
  if (event === true) {
    this.selectedMov = item;
    this.transaccion.MovStowageId = item.MovStowageId;
  } else {
    this.selectedMov = null;
    this.transaccion.MovStowageId = '';
  }
}

//Computeds:
function movimientosOptions() {
  let _this = this.$i18n.locale;
  return this.movimientos.map((mov) => Object.assign({}, mov, {
    MotionName: _this == 'en' ? mov.MovStowageName : mov.MovStowageNameEs,
    initialActive: this.selectedMov
      ? this.selectedMov.MovStowageId == mov.MovStowageId
      : false,
    _classes: 'text-center'
  }));
}

export default {
  name: 'add-movimientos-modal',
  mixins: [
    GeneralMixin,
    TransaccionMixin,
    ModalMixin
  ],
  props: {
    modal: Boolean
  },
  data,
  validations: TransacFormValidations,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    getMovimientos,
    handleMovimiento
  },
  computed: {
    movimientosOptions,
    fields,
  },
  watch: {
  }
}
</script>
<style lang="scss">
/*
.center-cell-embalaje {
  text-align: center;
}*/
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}

.center-cell {
  text-align: center;
}
.table-index {
  margin: 1.5rem !important;
  table {
    td {
      vertical-align: middle !important;
    }
  }
}
</style>